<template>
  <div class="page-coantainer">
    <el-dialog
      title="添加院校"
      :visible.sync="addData.isOpen"
      width="30%"
      :before-close="handleClose"
    >
      <div class="dialog-body">
        <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
          <el-form-item label="院校" prop="schoolName">
            <el-input v-model.trim="formData.schoolName"></el-input>
          </el-form-item>
          <el-form-item label="院校详情" prop="description">
            <el-input
              type="textarea"
              v-model.trim="formData.description"
            ></el-input>
          </el-form-item>
          <el-form-item label="开办专业" prop="description">
            <el-input
              type="textarea"
              v-model.trim="formData.homeTitle"
            ></el-input>
          </el-form-item>
          <el-form-item label="学制学费" prop="description">
            <el-input
              type="textarea"
              v-model.trim="formData.descTitle"
            ></el-input>
          </el-form-item>
          <el-form-item label="学校封面" prop="description">
            <el-upload
              class="avatar-uploader"
              :action="$uploadHttp"
              :show-file-list="false"
              :on-success="uploadImageSuccess"
              :before-upload="uploadImageHttp"
              :headers="headers"
            >
              <img v-if="formData.pic" :src="$fileHttp + '' + formData.pic" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="列表排序" prop="sort">
            <el-input
              type="number"
              v-model.trim="formData.sort"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submitAddMajor">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSchoolList, addSchool } from '@/api/api.js'

export default {
  props: {
    addData: {
      type: Object
    }
  },
  data () {
    return {
      isOpen: false,
      formData: {
        schoolName: '',
        descTitle: '',
        description: '',
        homeTitle: '',
        pic: '',
        sort: 1
      },
      headers: {
        Authorization: window.sessionStorage.getItem('token')
      },
      schoolList: [],
      rules: {
        schoolName: [
          { required: true, message: '请填写院校', trigger: 'blur' },
          {
            min: 2,
            max: 20,
            message: '长度在 2 到 20 个字符',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    /* 上传图片成功 */
    uploadImageSuccess (res, file) {
      this.formData.pic = res.data.fileName
    },
    /* 上传图片 */
    uploadImageHttp (file) {
      const isJPG = file.type === 'image/jpeg' || 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 20

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 20MB!')
      }
      return isJPG && isLt2M
    },
    /* 获取学校列表 */
    getSchoolData () {
      getSchoolList({
        currentPage: 1,
        pageSize: 20
      }).then((res) => {
        this.schoolList = res.data.list
      })
    },
    /* 添加专业 */
    submitAddMajor () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          addSchool(this.formData).then((res) => {
            this.$emit('closeAddDialog', { isOpen: false, type: 'success' })
            this.$refs.form.resetFields()
            this.$message({
              message: '添加成功',
              type: 'success'
            })
          })
        }
      })
    },
    /* 关闭弹窗 */
    handleClose () {
      this.$emit('closeAddDialog', { isOpen: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-coantainer {
  ::v-deep .el-dialog {
    &__body {
      max-height: 500px;
      overflow: auto;
    }
  }
}
</style>
<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
